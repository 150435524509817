<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar :isIcons="false" :navOptions="false" :signupOptions="false" :corporateOptions="false"/>

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100" style="padding-top: 50px; padding-bottom: 50px;">
        <div class="container">
            <div class="row justify-content-center mt-5">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Aviso de Privacidad de Checkup Digital</h4>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                            <p class="text-muted">
                                Hub de Pacientes DPPL, S.A.P.I. de C.V. (en adelante, “Duppla”), con domicilio en: Avenida Lázaro Cárdenas 2400, piso 5, local E1, Col. Del Valle Oriente, San Pedro Garza García, Nuevo León, pone a disposición de los usuarios de “Checkup Digital”, con página web <a href="https://www.duppla.doctor/" target="_blank">https://www.duppla.doctor/</a>, de conformidad en lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y sus disposiciones reglamentarias, es el responsable del uso y protección de sus datos personales incluyendo los sensibles, y al respecto le informamos lo siguiente.
                            </p>

                            <h5 class="card-title">¿Para qué fines utilizaremos sus datos personales, incluyendo los datos personales sensibles?</h5>
                            <p class="text-muted">
                                Los datos personales que sean facilitados por usted u otros usuarios de la presente página web, serán tratados por Duppla, de manera enunciativa, pero no limitativa, con la finalidad de prestar el servicio para poder determinar las probabilidades de que el usuario sin síntomas, ni preexistencia de cáncer, pueda llegar a desarrollar algún tipo de Cáncer en los próximos 5 (cinco) años (el “Servicio”), El usuario solicita:

                                <ul>
                                    <li>
                                        <b class="text-primary">Para verificar y confirmar que es mayor de edad:</b> Lo anterior con la finalidad de cersiorarse de que se le pueden prestar los Servicios solicitados; y en caso de que el Servicio lo solicite un menor de edad,  que este sea por conducto de padres o tutores.
                                    </li>
                                    <li>
                                        <b class="text-primary">Para la creación, actualización y conservación de su expediente como usuario:</b> Los Servicios ofrecidos por Duppla y otros servicios relacionados con el cuidado de la salud.  
                                    </li>
                                </ul>
                                De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para los Servicios solicitados, pero que nos permiten y facilitan brindarle una mejor atención:
                                <ul>
                                    <li>
                                        Para la valoración de otros servicios que en el futuro se puedan prestar a los usuarios, todos relacionados con el cuidado de la salud.
                                    </li>
                                </ul>
                                En caso de que no desee que sus datos personales, se utilicen para estos fines secundarios, indíquelo a continuación:
                            </p>

                            <h5 class="card-title">No consiento que mis datos personales se utilicen para los siguientes fines:</h5>
                            <p class="text-muted">
                                [  ] Prospección comercial.
                                <br/><br/>
                                La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los Servicios que solicita o contrata con nosotros.
                            </p>

                            <h5 class="card-title">¿Qué datos personales utilizaremos para estos fines?</h5>
                            <p class="text-muted">
                                Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, utilizaremos los siguientes datos personales:
                                <ul>
                                    <li>
                                        <b class="text-primary">Datos de identificación</b> (nombre, apellidos, correo electrónico, teléfono, etc.).
                                    </li>
                                    <li>
                                        <b class="text-primary">Datos económicos o de transacciones de bienes y servicios</b> (número de cuenta bancaria o de tarjeta bancaria para el pago de Servicios o datos de transacciones o pagos realizadas).
                                    </li>
                                    <li>
                                        <b class="text-primary">Datos de navegación</b> (datos de uso de la página web de Duppla).
                                    </li>
                                </ul>
                                Además por la naturaleza de los Servicios que Duppla presta, <strong>deberá tratar datos personales sensibles,</strong> que de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, son aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.
                                <br/><br/>
                                En razón de lo anterior, se tratarán los siguientes datos personales sensibles:
                                <ul>
                                    <li>
                                        Sexo de nacimiento;
                                    </li>
                                    <li>
                                        Detalle de síntomas o malestares relacionados con cáncer;
                                    </li>
                                    <li>
                                        Factores de riesgo hereditarios, como antecedentes de familiares con cáncer;
                                    </li>
                                    <li>
                                        Factores de riesgo hormonales, como bacterias y virus transmitidos sexualmente; datos de menstruación; datos de relaciones sexuales; embarazos; lactancia, entre otros.
                                    </li>
                                    <li>
                                        Factores relacionados con estilo de vida, tales como: fumar, beber alcohol, actividad física, dietas;
                                    </li>
                                    <li>
                                        Otras enfermedades: diabetes, hiperlimia y/u obesidad;
                                    </li>
                                </ul>
                                La negativa para el uso de sus datos personales para estas finalidades es un motivo para que le neguemos el Servicio que solicita a Duppla.
                            </p>

                            <h5 class="card-title">¿Qué debe garantizarnos al aportar sus datos personales?</h5>
                            <p class="text-muted">
                                Usted garantiza que los datos personales incluyendo los sensibles que aporte son verdaderos, exactos, completos y se encuentran actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.
                                <br/><br/>
                                En caso de que un tercero aporte datos pertenecientes a un usuario, éste garantiza que ha informado a dicho tercero de la totalidad de aspectos contenidos en la presente Política de Privacidad. Todo ello, con carácter previo al suministro de los datos personales
                                <br/><br/>
                                Duppla le informa que para aportar sus datos personales, de cualquier modo, a través de nuestra página web, debe ser mayor de 18 (dieciocho) años y usted se responsabiliza enteramente de tal declaración.

                            </p>
                            <h5 class="card-title">¿A quién se comunican sus datos?</h5>
                            <p class="text-muted">
                                Los datos personales recabados por Duppla para alcanzar las finalidades de tratamiento detalladas anteriormente serán comunicados únicamente a empleados y asesores de Duppla, exclusivamente para el correcto desarrollo de la relación jurídica, contractual y / o asistencial entablada entre el usuario y Duppla y, en su caso, el cumplimiento de obligaciones legales de Duppla.
                            </p>

                            <h5 class="card-title">¿Por cuánto tiempo conservaremos sus datos?</h5>
                            <p class="text-muted">
                                Duppla conservará sus datos personales durante el tiempo necesario para la prestación del Servicio solicitado y/o para alcanzar la finalidad de tratamiento perseguida.
                                <br/><br/>
                                Posteriormente, siempre que Usted  no haya ejercido su derecho de oposición, sus datos serán conservados durante los plazos legales que en cada caso resulten de aplicación, teniendo en cuenta la tipología de datos, así como la finalidad de tratamiento.

                            </p>
                            <h5 class="card-title">¿Qué medidas adoptamos para proteger sus datos personales?</h5>
                            <p class="text-muted">
                                Duppla ha adoptado los niveles de seguridad requeridos para la protección de sus datos personales incluyendo los sensibles, y se han instalado los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados a través del Sitio Web de Duppla.
                            </p>
                            <h5 class="card-title">¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso? </h5>
                            <p class="text-muted">
                                Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
                                <br/><br/>
                                <strong>Para el ejercicio de cualquiera de los derechos ARCO,</strong> usted deberá presentar la solicitud que corresponda al derecho que desea ejercer, a través de correo electrónico. 
                                <br/><br/>
                                Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:
                            </p>

                            <h5 class="card-title">¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante legal, así como la personalidad de este último?</h5>
                            <p class="text-muted">
                                A través de correo electrónico, enviando una copia de la identificación del titular, y en su caso del representante legal, así como el poder notarial de este último.
                            </p>
                            <h5 class="card-title">¿Qué información y/o documentación deberá contener la solicitud?</h5>
                            <p class="text-muted">
                                La solicitud deberá contener los motivos por los que desea ejercer sus Derechos ARCO.
                                <br/><br/>
                                <strong>En caso de tratarse de Rectificación de datos,</strong> deberá señalar que información se pretende corregir. Dicha solicitud deberá estar debidamente firmada por el titular, o en su caso, por su representante legal, y mencionar la dirección de correo electrónico de contacto, a través de la cual se comunicará la respuesta. 
                                <br/><br/>
                                Deberá acompañar su solicitud, con la identificación del titular, y si es un apoderado legal, poder copia de la identificación oficial del representante.
                            </p>
                            <h5 class="card-title">¿En cuántos días le daremos respuesta a su solicitud?</h5>
                            <p class="text-muted">
                                5 (cinco) días hábiles posteriores, contados a partir de la fecha en que realizó su solicitud.
                            </p>
                            <h5 class="card-title">¿Por qué medio le comunicaremos la respuesta a su solicitud?</h5>
                            <p class="text-muted">
                                A través de correo electrónico.
                                <br/><br/>
                                Usted puede revocar su consentimiento para el uso de sus datos personales.
                                Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.
                                <br/><br/>
                                Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
                                <br/><br/>
                                Asimismo, deberá considerar que, para la mayoría de los fines, la revocación del consentimiento puede implicar que no le podamos seguir prestando el Servicio, teniendo como consecuencia la terminación de la relación con Duppla.
                                <br/><br/>
                                Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo siguiente:
                                <ol type="a">
                                    <li>
                                        ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?
                                        A través de correo electrónico a <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a>. 
                                    </li>
                                    <li>
                                        ¿Qué información y/o documentación deberá contener la solicitud?
                                        La solicitud deberá contener los motivos por cuáles revoca su consentimiento al tratamiento de sus datos personales, y contar con firma autógrafa del titular, o en su caso de su representante legal, además debe establecer un correo electrónico de contacto. Dicha solicitud deberá estar acompañada de la identificación oficial del titular, o en su caso, poder notarial y copia de la identificación oficial del representante legal.
                                    </li>

                                    <li>
                                        ¿En cuántos días le daremos respuesta a su solicitud?
                                        Cinco (5) días hábiles posteriores, contados a partir de la fecha en que realizó su solicitud.
                                    </li>
                                    <li>
                                        ¿Por qué medio le comunicaremos la respuesta a su solicitud?
                                        Por medio del correo electrónico, a la dirección de correo establecida en su solicitud.
                                    </li>
                                </ol>

                            </p>
                            <h5 class="card-title">¿Cómo puede limitar el uso o divulgación de su información personal? </h5>
                            <p class="text-muted">
                                Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos la dirección de correo electrónico: <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a> para que través de ese medio nos contacte, y Duppla pueda llevar a cabo los fines antes mencionados.
                            </p>
                            <h5 class="card-title">¿Cómo puede conocer los cambios en este Aviso de Privacidad?</h5>
                            <p class="text-muted">
                                El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de cambios en la ley; de nuestras propias necesidades por los servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras razones.
                                <br/><br/>
                                En virtud de lo anterior, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de Privacidad, a través de: un informe de dicha situación, mismo que será enviado al correo electrónico registrado en nuestras bases de datos, de los titulares de datos personales, o en su caso de sus representantes legales, en un plazo no mayor a cinco (5) días hábiles posteriores, a que se hayan llevado a cabo las modificaciones o actualizaciones.

                            </p>
                            <h5 class="card-title">Su consentimiento para el tratamiento de sus datos personales.</h5>
                            <p class="text-muted">
                                Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en el presente Aviso de Privacidad.[  ]
                            </p>

                            <p class="text-muted">
                                Última actualización: marzo 2023.
                            </p>


                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!--Switcher /-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
